<template lang="">
  <v-container fluid>
    <v-card>
      <v-toolbar color="red darken-2" dark height="40" class="mt-n2">
        <v-spacer></v-spacer>
        <v-toolbar-title>Sensorial Inspector</v-toolbar-title>
        <v-spacer></v-spacer>
      </v-toolbar>
      <v-card-text>
        <v-row class="mt-n4 mt-sm-1 mt-lg-1">
          <v-col cols="12" sm="4" md="3" lg="2" class="mb-n8">
            <label>N° Placa</label>
            <v-text-field
              dense
              :rules="[
                (v) =>
                  /^[A-Za-z0-9]*$/.test(v) || 'Solo deben ser numeros y letras',
              ]"
              v-model="placa"
              @keyup.enter="consultarOrdenesTrabajo"
              outlined
            ></v-text-field>
          </v-col>
          <v-col cols="12" sm="4" md="3" lg="2">
            <label>Tipo de vehiculo</label>
            <v-select
              :items="tipos_vehiculos"
              item-text="tipo_vehiculo"
              item-value="id_tipo_vehiculo"
              v-model="tipo_vehiculo"
              outlined
              dense
            ></v-select>
          </v-col>
          <v-col
            cols="12"
            sm="2"
            md="2"
            lg="1"
            class="d-flex align-center justify-center mb-1 mt-n8 mt-sm-0 mt-lg-0 mb-n3 mb-sm-0 mb-lg-0"
          >
            <v-btn color="primary" @click="consultarOrdenesTrabajo">
              Buscar
              <v-icon class="ml-1"> mdi-magnify </v-icon>
            </v-btn>
          </v-col>
        </v-row>

        <v-data-table
          :items-per-page="-1"
          class="mt-4"
          :header-props="{ sortByText: 'Ordenar por' }"
          v-if="!consultando_ordenes_trabajo"
          :headers="headers"
          no-data-text="No se encontraron resultados"
          :items="info_datatable"
          dense
        >
          <template v-slot:item.opc="props">
            <div class="text-center">
              <v-menu offset-y>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon v-bind="attrs" v-on="on">mdi-dots-vertical</v-icon>
                </template>
                <v-list>
                  <v-list-item
                    class="mx-4"
                    v-for="(item, index) in items_opciones"
                    :key="index"
                    @click="opcionesMenu(props.item, item.value)"
                  >
                    <v-list-item-title class="personalizar">
                      <span v-if="(props.item.estado == 0) & (item.value == 2)"
                        >Activar</span
                      >
                      <span v-else>{{ item.title }}</span>
                    </v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>
            </div>
          </template>
          <template v-slot:item.tipo_vehiculo="{ item }">
            <div>{{ validarTipoVehiculo(item.lin_id) }}</div>
          </template>
          <template v-slot:item.tarifa="{ item }">
            <div v-if="item.ot_tiporevision != 0 && item.ot_tiporevision != 2">
              ${{ item.tarifa | moneda }}
            </div>
            <div v-else>No Aplica</div>
          </template>


          <template v-slot:item.tipo_revision="{ item }">
            <div>
              {{ validarTipoRevision(item.ot_tiporevision) }}
            </div>
          </template>

          <template v-slot:item.acciones="{ item }">
            <div class="pa-2">
              <v-btn small color="success" @click="registrarSensorial(item)"
                >Registrar Sensorial</v-btn
              >
            </div>
          </template>
        </v-data-table>
        <v-col
          cols="12"
          v-else
          style="
            display: flex;
            align-items: center;
            justify-content: center;
            height: 200px;
          "
        >
          <v-spacer></v-spacer>
          <v-progress-circular
            :size="100"
            color="primary"
            indeterminate
          ></v-progress-circular>
          <v-spacer></v-spacer>
        </v-col>
      </v-card-text>
    </v-card>
    <v-dialog v-model="modal_no_realizar_inspeccion" width="500">
      <ModalNoRealizarInspeccion
        :ot_id="ot_id"
        :runt="runt"
        :placa="placa_prop"
        @cerrar-modal="modal_no_realizar_inspeccion = false"
        @no-realizar-formulario="consultarOrdenesTrabajo"
      />
    </v-dialog>
  </v-container>
</template>
<script>
import ModalNoRealizarInspeccion from "./components/ModalNoRealizarInspeccion.vue";
import ListaDataService from "../lista_preguntas/services/ListaDataService";
import sensorialService from "./services/sensorialService";
export default {
  data: () => ({
    consultando_ordenes_trabajo: false,

    headers: [
      { text: "Opc", value: "opc", align: "center" },
      { text: "N° Orden Trabajo", value: "ot_id", align: "center" },
      { text: "Tipo revisión", value: "tipo_revision", align: "center" },
      { text: "Placa", value: "placa", align: "center" },
      { text: "Tipo Vehiculo", value: "tipo_vehiculo", align: "center" },
      {
        text: "Nombre Propietario",
        value: "nombre_propietario",
        align: "center",
      },
      // {
      //   text: "Tarifa",
      //   value: "tarifa",
      //   align: "center",
      // },
      { text: "Acciones", value: "acciones", align: "center" },
    ],

    items_opciones: [
      {
        title: "No realizar",
        value: 1,
      },
    ],

    info_datatable: [],

    tipos_vehiculos: [],
    tipo_vehiculo: -1,

    tipos_revisiones: [],

    placa: null,

    //---------- MODALES -----------
    ot_id: null,

    runt: null,

    placa_prop: null,

    modal_no_realizar_inspeccion: false,
  }),
  components: {
    ModalNoRealizarInspeccion,
  },
  async created() {
    //Saber peso del local storage
    // var _lsTotal=0,_xLen,_x;for(_x in localStorage){ if(!localStorage.hasOwnProperty(_x)){continue;} _xLen= ((localStorage[_x].length + _x.length)* 2);_lsTotal+=_xLen; console.log(_x.substr(0,50)+" = "+ (_xLen/1024).toFixed(2)+" KB")};console.log("Total = " + (_lsTotal / 1024).toFixed(2) + " KB");

    this.$store.state.stateLoad = true;
    await ListaDataService.getTiposVehiculo().then((response) => {
      this.tipos_vehiculos = response.data;
      this.tipos_vehiculos.unshift({
        id_tipo_vehiculo: -1,
        tipo_vehiculo: "Todos",
      });
    });

    await sensorialService
      .consultarOrdenesTrabajo({
        placa: this.placa,
        tipo_vehiculo: this.tipo_vehiculo,
      })
      .then((response) => {
        this.info_datatable = response.data;
      });

    await sensorialService.tiposRevisiones().then((response) => {
      this.tipos_revisiones = response.data;
    });

    this.$store.state.stateLoad = false;
  },
  methods: {
    registrarSensorial(item) {
      if (item.lin_id == 1) {
        this.$router.push({
          path: "formularioCarros/",
          query: {
            ot_id: item.ot_id,
            placa: item.placa,
            tipo_revision: item.ot_tiporevision,
            runt: item.ot_runt,
            tarifa:item.tarifa,
            iva:item.iva,
            valor_servicio: item.valor_servicio,
          },
        });
      } else {
        this.$router.push({
          path:
            "formularioMotos/",
            query: {
            ot_id: item.ot_id,
            placa: item.placa,
            tipo_revision: item.ot_tiporevision,
            runt: item.ot_runt,
            tarifa:item.tarifa,
            iva:item.iva,
            valor_servicio: item.valor_servicio,
          },
        });
      }
    },
    async consultarOrdenesTrabajo() {
      this.consultando_ordenes_trabajo = true;
      await sensorialService
        .consultarOrdenesTrabajo({
          placa: this.placa,
          tipo_vehiculo: this.tipo_vehiculo,
        })
        .then((response) => {
          this.info_datatable = response.data;
        });
      this.consultando_ordenes_trabajo = false;
    },

    validarTipoVehiculo(id_tipo_vehiculo) {
      let texto = "";
      if (this.tipos_vehiculos.length > 0) {
        this.tipos_vehiculos.forEach((tipo_vehiculo) => {
          if (tipo_vehiculo.id_tipo_vehiculo == id_tipo_vehiculo) {
            texto = tipo_vehiculo.tipo_vehiculo;
          }
        });

        return texto;
      }
    },

    validarTipoRevision(id_tipo_revision) {
      let texto = "";
      if (this.tipos_revisiones.length > 0) {
        this.tipos_revisiones.forEach((tipo_revision) => {
          if (tipo_revision.id_rtm_oficial_tipo == id_tipo_revision) {
            texto = tipo_revision.tipo_rtm_oficial;
          }
        });
      }
      texto = texto == "" ? "No indetificado" : texto;

      return texto;
    },

    /**
     * Funcion que evalua la opcion seleccionada y hace una accion
     *
     * @param {Object} item los datos del registro seleccionado
     * @param {number} value la opcion seleccionada
     * @returns {void}
     */
    opcionesMenu(item, value) {
      switch (value) {
        case 1:
          this.runt = item.ot_runt;
          this.placa_prop = item.placa;
          this.ot_id = item.ot_id;
          this.modal_no_realizar_inspeccion = true;
          break;
        default:
          break;
      }
    },
  },
};
</script>
