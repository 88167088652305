import api from "../../general/axiosInstance.js";

const relative_path = "/sensorial";

export default {
  consultarOrdenesTrabajo(params) {
    return api.post(relative_path + "/consultar-ordenes-trabajo",params);
  },

  getMotivosNoRealizarInspeccion() {
    return api.get(relative_path + "/motivos-no-realizar-inspeccion");
  },

  
  tiposRevisiones() {
    return api.get(relative_path + "/tipos-revisiones");
  },

  noRealizarInspeccion(campos) {
    return api.post(relative_path + "/no-realizar-inspeccion-formulario",campos);
  },

  crearNuevoMotivoNoInspeccion(nuevo_motivo){
    return api.post(relative_path + "/crear-nuevo-motivo-no-inspeccion",nuevo_motivo);
  },
  informeSensorial(params){
    return api.post(relative_path + "/informe-sensorial",params);
  }
};
