var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":""}},[_c('v-card',[_c('v-toolbar',{staticClass:"mt-n2",attrs:{"color":"red darken-2","dark":"","height":"40"}},[_c('v-spacer'),_c('v-toolbar-title',[_vm._v("Sensorial Inspector")]),_c('v-spacer')],1),_c('v-card-text',[_c('v-row',{staticClass:"mt-n4 mt-sm-1 mt-lg-1"},[_c('v-col',{staticClass:"mb-n8",attrs:{"cols":"12","sm":"4","md":"3","lg":"2"}},[_c('label',[_vm._v("N° Placa")]),_c('v-text-field',{attrs:{"dense":"","rules":[
              function (v) { return /^[A-Za-z0-9]*$/.test(v) || 'Solo deben ser numeros y letras'; } ],"outlined":""},on:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.consultarOrdenesTrabajo.apply(null, arguments)}},model:{value:(_vm.placa),callback:function ($$v) {_vm.placa=$$v},expression:"placa"}})],1),_c('v-col',{attrs:{"cols":"12","sm":"4","md":"3","lg":"2"}},[_c('label',[_vm._v("Tipo de vehiculo")]),_c('v-select',{attrs:{"items":_vm.tipos_vehiculos,"item-text":"tipo_vehiculo","item-value":"id_tipo_vehiculo","outlined":"","dense":""},model:{value:(_vm.tipo_vehiculo),callback:function ($$v) {_vm.tipo_vehiculo=$$v},expression:"tipo_vehiculo"}})],1),_c('v-col',{staticClass:"d-flex align-center justify-center mb-1 mt-n8 mt-sm-0 mt-lg-0 mb-n3 mb-sm-0 mb-lg-0",attrs:{"cols":"12","sm":"2","md":"2","lg":"1"}},[_c('v-btn',{attrs:{"color":"primary"},on:{"click":_vm.consultarOrdenesTrabajo}},[_vm._v(" Buscar "),_c('v-icon',{staticClass:"ml-1"},[_vm._v(" mdi-magnify ")])],1)],1)],1),(!_vm.consultando_ordenes_trabajo)?_c('v-data-table',{staticClass:"mt-4",attrs:{"items-per-page":-1,"header-props":{ sortByText: 'Ordenar por' },"headers":_vm.headers,"no-data-text":"No se encontraron resultados","items":_vm.info_datatable,"dense":""},scopedSlots:_vm._u([{key:"item.opc",fn:function(props){return [_c('div',{staticClass:"text-center"},[_c('v-menu',{attrs:{"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
            var on = ref.on;
            var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({},'v-icon',attrs,false),on),[_vm._v("mdi-dots-vertical")])]}}],null,true)},[_c('v-list',_vm._l((_vm.items_opciones),function(item,index){return _c('v-list-item',{key:index,staticClass:"mx-4",on:{"click":function($event){return _vm.opcionesMenu(props.item, item.value)}}},[_c('v-list-item-title',{staticClass:"personalizar"},[((props.item.estado == 0) & (item.value == 2))?_c('span',[_vm._v("Activar")]):_c('span',[_vm._v(_vm._s(item.title))])])],1)}),1)],1)],1)]}},{key:"item.tipo_vehiculo",fn:function(ref){
            var item = ref.item;
return [_c('div',[_vm._v(_vm._s(_vm.validarTipoVehiculo(item.lin_id)))])]}},{key:"item.tarifa",fn:function(ref){
            var item = ref.item;
return [(item.ot_tiporevision != 0 && item.ot_tiporevision != 2)?_c('div',[_vm._v(" $"+_vm._s(_vm._f("moneda")(item.tarifa))+" ")]):_c('div',[_vm._v("No Aplica")])]}},{key:"item.tipo_revision",fn:function(ref){
            var item = ref.item;
return [_c('div',[_vm._v(" "+_vm._s(_vm.validarTipoRevision(item.ot_tiporevision))+" ")])]}},{key:"item.acciones",fn:function(ref){
            var item = ref.item;
return [_c('div',{staticClass:"pa-2"},[_c('v-btn',{attrs:{"small":"","color":"success"},on:{"click":function($event){return _vm.registrarSensorial(item)}}},[_vm._v("Registrar Sensorial")])],1)]}}],null,false,1043865211)}):_c('v-col',{staticStyle:{"display":"flex","align-items":"center","justify-content":"center","height":"200px"},attrs:{"cols":"12"}},[_c('v-spacer'),_c('v-progress-circular',{attrs:{"size":100,"color":"primary","indeterminate":""}}),_c('v-spacer')],1)],1)],1),_c('v-dialog',{attrs:{"width":"500"},model:{value:(_vm.modal_no_realizar_inspeccion),callback:function ($$v) {_vm.modal_no_realizar_inspeccion=$$v},expression:"modal_no_realizar_inspeccion"}},[_c('ModalNoRealizarInspeccion',{attrs:{"ot_id":_vm.ot_id,"runt":_vm.runt,"placa":_vm.placa_prop},on:{"cerrar-modal":function($event){_vm.modal_no_realizar_inspeccion = false},"no-realizar-formulario":_vm.consultarOrdenesTrabajo}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }