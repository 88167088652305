<template>
  <v-card>
    <v-toolbar dark color="red darken-2" height="40">
      <v-spacer></v-spacer>
      <v-toolbar-title>No realizar inspección</v-toolbar-title>
      <v-spacer></v-spacer>
    </v-toolbar>
    <v-card-text>
      <div v-if="!cargando_datos_vista">
        <v-col cols="12" class="mb-n8">
          <label
            >Motivo<v-btn
              v-show="crear_motivo == false"
              class="mt-n1"
              x-small
              text
              color="success"
              @click="crear_motivo = true"
              >[+ AGREGAR ]</v-btn
            >
            <v-btn
              v-show="crear_motivo"
              :disabled="creando_nuevo_motivo || nuevo_motivo_no_realizacion == null || nuevo_motivo_no_realizacion == ''"
              class="mt-n1"
              x-small
              text
              color="success"
              @click="crearNuevoMotivo()"
              >[+ GUARDAR ]</v-btn
            >
            <v-btn
              v-show="crear_motivo"
              :disabled="creando_nuevo_motivo"
              class="mt-n1"
              x-small
              text
              color="red"
              @click="crear_motivo = false"
              >[ CANCELAR ]</v-btn
            ></label
          >
          <v-select
            v-if="!crear_motivo"
            outlined
            dense
            :items="motivos_no_realizacion"
            v-model="motivo_no_realizacion"
            item-text="motivo"
            item-value="id"
          >
          </v-select>
          <v-text-field
            v-else
            :loading="creando_nuevo_motivo"
            outlined
            dense
            v-model="nuevo_motivo_no_realizacion"
          >
          </v-text-field>
        </v-col>
        <v-col cols="12">
          <label>Comentarios</label>
          <v-textarea height="70" outlined v-model="comentario"> </v-textarea>
        </v-col>
      </div>
      <v-col
        v-else
        cols="12"
        style="
          display: flex;
          align-items: center;
          justify-content: center;
          height: 130px;
        "
      >
        <v-spacer></v-spacer>
        <v-progress-circular
          :size="100"
          color="primary"
          indeterminate
        ></v-progress-circular>
        <v-spacer></v-spacer>
      </v-col>
    </v-card-text>

    <v-card-actions class="mt-n10 justify-end" v-if="!cargando_datos_vista">
      <v-btn
        color="red"
        class="white--text"
        :disabled="guardando_cambios"
        @click="$emit('cerrar-modal')"
      >
        Cancelar
      </v-btn>
      <v-btn
        color="success"
        :disabled="motivo_no_realizacion == null || crear_motivo"
        :loading="guardando_cambios"
        @click="guardarCambios"
      >
        Guardar
      </v-btn>
    </v-card-actions>
  </v-card>
</template>
<script>
import sensorialService from "../services/sensorialService";

export default {
  props: ["ot_id", "runt", "placa"],
  data: () => ({
    //Crear nuevo motivo
    crear_motivo: false,
    nuevo_motivo_no_realizacion: null,
    creando_nuevo_motivo: false,

    cargando_datos_vista: false,
    guardando_cambios: false,

    motivos_no_realizacion: [],
    motivo_no_realizacion: null,

    comentario: null,
  }),
  async created() {
    this.cargando_datos_vista = true;

    await sensorialService.getMotivosNoRealizarInspeccion().then((response) => {
      this.motivos_no_realizacion = response.data;
    });
    this.cargando_datos_vista = false;
  },

  methods: {
    async guardarCambios() {
      this.$swal
        .fire({
          title: "Estas seguro de no realizar esta inspeccion?",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          cancelButtonText: "Cancelar",
          confirmButtonText: "Confirmar",
        })
        .then(async (result) => {
          if (result.isConfirmed) {
            this.guardando_cambios = true;
            await sensorialService
              .noRealizarInspeccion({
                ot_id: this.ot_id,
                runt: this.runt,
                placa: this.placa,
                id_motivo: this.motivo_no_realizacion,
                comentario: this.comentario,
              })
              .then((response) => {
                this.$swal
                  .fire({
                    title: "Cambios Guardados",
                    icon: "success",
                    allowEscapeKey: false,
                    allowOutsideClick: false,
                    allowEnterKey: false,
                    position: "center",
                    timer: 2000,
                    showConfirmButton: false,
                    confirmButtonText: "Ok",
                  })
                  .then(() => {
                    this.motivo_no_realizacion = null;
                    this.comentario = null;
                    this.$emit("no-realizar-formulario");
                    this.$emit("cerrar-modal");
                  });
              });

            this.guardando_cambios = false;
          }
        });
    },

    async crearNuevoMotivo() {
      this.creando_nuevo_motivo = true;
      await sensorialService
        .crearNuevoMotivoNoInspeccion({nuevo_motivo:this.nuevo_motivo_no_realizacion})
        .then((response) => {
          this.motivos_no_realizacion.push({
            id: response.data,
            motivo: this.nuevo_motivo_no_realizacion,
          });
          this.motivo_no_realizacion = response.data;
          this.crear_motivo = false;
          this.nuevo_motivo_no_realizacion = null;
        })
        .catch((error) => {
          console.log(error);
          this.$swal.fire({
            title: "Error al crear nuevo motivo",
            icon: "error",
            allowEscapeKey: false,
            allowOutsideClick: false,
            allowEnterKey: false,
            position: "center",
            timer: 2000,
            showConfirmButton: false,
            confirmButtonText: "Ok",
          });
        });

      this.creando_nuevo_motivo = false;
    },
  },
};
</script>
<style lang=""></style>
